/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

/**
  * Nordic dependencies
  */
const injectI18n = require('nordic/i18n/injectI18n');

/**
  * Andes dependencies
  */
const { Modal } = require('@andes/modal');
const { Button } = require('@andes/button');
const Message = require('@andes/message');

/**
  * Local dependencies
  */
const classNames = require('classnames');
const TagSpecialMessage = require('../TagSpecialMessage');
const AmountFormat = require('../AmountFormat');
const { addTime, getDate, dateDuration, addTimeAndSet, formatDate, getNumberOfDay, difference, sameDate } = require('../../helpers/dateLibHelper');

/**
  * ModalBillingExplanation Component.
  */
class ModalBillingExplanation extends React.Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    const { onClose } = this.props;
    onClose();
  }

  renderButtonFilled() {
    const { i18n } = this.props;
    return (
      <Button
        hierarchy="quiet"
        onClick={this.closeModal}
      >
        {i18n.gettext('Entendido')}
      </Button>
    );
  }

  render() {
    const {
      i18n,
      visible,
      hasFreeTrial,
      freeTrialPeriod,
      frequencyTrial,
      recurringInfo,
      customAmount,
      device,
      amountLimits,
    } = this.props;

    const {
      transactionAmount,
      transactionAmountProportional,
      currencyData,
      billingDay,
      billingStartProportional,
    } = recurringInfo;
    const buttonFilled = this.renderButtonFilled();

    // base day for calculation
    const getDaysInCurrentMonth = () => {
      const date = new Date();
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    };

    const daysInCurrentMonth = getDaysInCurrentMonth();
    const messageText = hasFreeTrial
      ? i18n.gettext('En {0} días, pagarás un monto proporcional por los días que falten hasta el primer cobro mensual.', freeTrialPeriod || daysInCurrentMonth)
      : i18n.gettext('Pagarás un monto proporcional por los días transcurridos entre hoy y la fecha del primer cobro mensual.');

    const freeTrialStartDate = formatDate(undefined, 'DD/MM');

    let nextFullPaymentDate = '';
    const currentNumberDay = getNumberOfDay();
    const hasFreeTrialPeriodCustom = freeTrialPeriod ? addTime(undefined, parseInt(freeTrialPeriod, 10), 'days') : addTime(undefined, 1, 'M');

    const proportionalStartDate = hasFreeTrial
      ? hasFreeTrialPeriodCustom
      : getDate();


    const FormatedProportionalStartDate = formatDate(proportionalStartDate, 'DD/MM');

    const formatedBillingDay = `${getNumberOfDay(undefined, billingDay, 'DD')}`;
    let offsetMonth = 0;

    if (hasFreeTrial) offsetMonth += (freeTrialPeriod ? parseInt(freeTrialPeriod, 10) : daysInCurrentMonth);
    if (currentNumberDay > billingDay) offsetMonth += daysInCurrentMonth;
    nextFullPaymentDate = addTimeAndSet(undefined, offsetMonth, 'days', 'date', formatedBillingDay);

    const formatedNextFullPaymentDate = formatDate(nextFullPaymentDate, 'DD/MM');

    const proportionalDays = dateDuration(difference(nextFullPaymentDate, proportionalStartDate)).asDays();
    const amount = transactionAmount || customAmount;
    const proportionalPorcentage = (proportionalDays / daysInCurrentMonth) * 100;
    const proportionalAmount = billingStartProportional ? (transactionAmountProportional || (amount * proportionalPorcentage) / 100) : transactionAmount; // Calcular en base a la cantidad de dias free trial

    const proportionalAmountFinal = () => {
      const montoMinSite = amountLimits.minAmount;
      // validamos que el monto proporcional sea mayor al minimo del site sino es mayor se cobrará monto min, si es mayor se cobrará proporcional.
      return (proportionalAmount > montoMinSite) ? proportionalAmount : montoMinSite;
    };


    const hasPropportionalPayment = !sameDate(proportionalStartDate, nextFullPaymentDate);
    const timelineTitle = hasFreeTrial
      ? i18n.gettext('Estos serán tus primeros pagos:')
      : i18n.gettext('Este será tu primer pago:');

    const modalBilling = classNames(
      'modal-billing',
      hasPropportionalPayment || 'small-heigth',
    );

    const isDesktop = device === 'desktop';

    return (
      <Modal
        className={modalBilling}
        open={visible}
        type={isDesktop ? 'large' : 'card'}
        title={i18n.gettext('Tu suscripción se cobrará el día {0} de cada mes', billingDay)}
        onClose={this.closeModal}
        actions={{
          primary: buttonFilled,
        }}
        maxWidth={isDesktop ? 512 : null}
        minHeight={isDesktop ? 416 : null}
      >
        {(hasPropportionalPayment && billingStartProportional)
          && (
            <Message
              hierarchy="quiet"
              color="accent"
            >
              <div>
                {messageText}
              </div>
            </Message>
          )}
        <div className="modal__description">
          <p>{timelineTitle}</p>
        </div>
        <div className="milestones-container">
          <ul className="milestones">
            {hasFreeTrial
              && (
                <li className="milestone-child">
                  <div className="milestone-child__date">{freeTrialStartDate}</div>
                  <div className="milestone-child__amount">
                    <AmountFormat
                      currencyFormat={currencyData}
                      amount={0}
                    />
                  </div>
                  <TagSpecialMessage
                    label={freeTrialPeriod ? i18n.gettext('{0} días gratis', freeTrialPeriod) : i18n.gettext('1 {0} gratis', frequencyTrial)}
                    icon="gift"
                  />
                </li>
              )}
            {hasPropportionalPayment
              && (
                <li className="milestone-child">
                  <div className="milestone-child__date">{FormatedProportionalStartDate}</div>
                  <div className="milestone-child__amount">
                    <AmountFormat
                      currencyFormat={currencyData}
                      amount={proportionalAmountFinal()}
                      size={18}
                    />
                  </div>
                  <TagSpecialMessage
                    label={billingStartProportional ? i18n.gettext('Monto del {0} al {1}', [FormatedProportionalStartDate, formatedNextFullPaymentDate]) : i18n.gettext('Inicio de la suscripción')}
                    icon="calendar"
                    borderColor="lightBlue"
                  />
                </li>
              )}
            <li className="milestone-child">
              <div className="milestone-child__date">{formatedNextFullPaymentDate}</div>
              <div className="milestone-child__amount">
                <AmountFormat
                  currencyFormat={currencyData}
                  amount={amount}
                  size={18}
                />
              </div>
              <TagSpecialMessage
                label={i18n.gettext('Primer cobro mensual')}
                icon="calendar"
                borderColor="lightBlue"
              />
            </li>
          </ul>
        </div>

      </Modal>
    );
  }
}

/**
  * ModalBillingExplanation defaultProps
  */
ModalBillingExplanation.defaultProps = {
  i18n: {
    gettext: t => t,
  },
};

/**
  * ModalBillingExplanation propTypes
  */
ModalBillingExplanation.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

/**
  * Inject i18n context as props into ModalBillingExplanation.
  */
module.exports = injectI18n(ModalBillingExplanation);
